const Footer = () => {

  return (
    <div className="footer">
      <p>By Florian Wilisch - 2021</p>
    </div>    
  )

}

export default Footer